import { initializeAppCheck, getToken, ReCaptchaV3Provider } from "firebase/app-check";

import { config } from './index';

const { app } = require('./firesBaseConfig');


// -----------------------------------------------
// Pass your reCAPTCHA v3 site key (public key) to activate(). Make sure this
// key is the counterpart to the secret key you set in the Firebase console.
let appCheck;

if (config.env === 'staging') {
  appCheck = initializeAppCheck(app, {
    provider: new ReCaptchaV3Provider("6LfAc2wkAAAAABZBGlEatYuU947DHHfFaBz3PvBR"),
  });
} else if (config.env === 'prod') {

  appCheck = initializeAppCheck(app, {
    provider: new ReCaptchaV3Provider("6Lc6TewkAAAAAHyUEx4JuzP9AK8G9Kvz5TsDYc0s"),
  });
}

export const getAppCheckToken = async () => {
  let appCheckTokenResponse;

  try {
    appCheckTokenResponse = await getToken(appCheck, /* forceRefresh= */ false);

  } catch (err) {
    // Handle any errors if the token was not retrieved.
    console.error(err);
  }
  return appCheckTokenResponse;
};